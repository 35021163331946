var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"fixed top-0 h-14 w-full pl-12 pr-9 bg-white flex items-center justify-between z-50 nav-shadow"},[_c('div',{staticClass:"flex h-full gap-12"},[_c('div',{staticClass:"relative flex items-center gap-1.5"},[_c('NuxtLink',{staticClass:"cursor-pointer flex items-center h-full text-xl font-black -tracking-[0.5px] text-theme-primary",attrs:{"to":_vm.ROUTES.HOME},nativeOn:{"click":function($event){return _vm.fireGAEvent('home')}}},[_vm._v("\n                Yocket Prep\n            ")]),_vm._v(" "),_c('Chevron',{staticClass:"w-5 h-5 cursor-pointer transition-all duration-150",class:_vm.showYocketProductsDropdown ? '-rotate-90' : 'rotate-90',nativeOn:{"click":function($event){return _vm.toggleYocketProductsDropdown.apply(null, arguments)}}}),_vm._v(" "),(_vm.showYocketProductsDropdown)?_c('YocketProductsRedirectionsDropdown',{staticClass:"absolute top-[85%]",attrs:{"sourceModule":_vm.ANALYTIC_SOURCE_MODULES.NAVBAR},on:{"closeDropdown":function($event){_vm.showYocketProductsDropdown = false}}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"h-full flex items-center",class:_vm.isAuthenticated
                    ? _vm.isPremiumUser
                        ? 'space-x-4 lg:space-x-6'
                        : 'space-x-3 lg:space-x-4 xl:space-x-6'
                    : 'space-x-6'},[(!_vm.isAuthenticated)?_c('NavItem',{attrs:{"label":"Home","redirectTo":_vm.ROUTES.HOME},on:{"select":function($event){return _vm.fireGAEvent('home')}}}):(_vm.isFeatureAllowed(_vm.PLATFORM_FEATURES.DASHBOARD, _vm.featurePermissions))?_c('NavItem',{attrs:{"id":"dashboard-selector","label":"Dashboard","redirectTo":_vm.ROUTES.DASHBOARD,"isActive":_vm.$route.path.includes(_vm.ROUTES.DASHBOARD),"isDropdown":_vm.isLearningDashboardAccessible},nativeOn:{"click":function($event){return _vm.$router.push(_vm.ROUTES.DASHBOARD)}}},[(_vm.isLearningDashboardAccessible)?_c('DashboardSelector',{on:{"fireEvent":function($event){return _vm.fireGAEvent($event)}}}):_vm._e()],1):_vm._e(),_vm._v(" "),(
                    !_vm.isAuthenticated ||
                    _vm.isFeatureAllowed(_vm.PLATFORM_FEATURES.PREP_TOOLS, _vm.featurePermissions)
                )?_c('NavItem',{attrs:{"label":"Prep Tools","redirectTo":_vm.ROUTES.CLASSES_LP,"isActive":[_vm.ROUTES.VOCAB, _vm.ROUTES.PLANNER_LP, _vm.ROUTES.PLANNER].includes(_vm.$route.path),"isDropdown":true}},[_c('PrepToolsSelector',{on:{"fireEvent":function($event){return _vm.fireGAEvent($event)}}})],1):_vm._e(),_vm._v(" "),(
                    (_vm.liveClassAccess || _vm.isLiveClassUpgradeAvailable) &&
                    _vm.isFeatureAllowed(
                        _vm.PLATFORM_FEATURES.LIVE_CLASS_LANDING_PAGE,

                        _vm.featurePermissions
                    )
                )?_c('NavItem',{attrs:{"label":"Live Classes","redirectTo":_vm.liveClassAccess ? _vm.ROUTES.CLASSES_LP : _vm.ROUTES.CLASSES,"showTag":_vm.isLiveClassUpgradeAvailable,"tagLabel":"Upgrade","tagColor":"yellow"},on:{"select":function($event){return _vm.fireGAEvent('liveClass')}}}):(
                    !_vm.isAuthenticated ||
                    (!_vm.isPremiumUser &&
                        _vm.isFeatureAllowed(_vm.PLATFORM_FEATURES.PREP_COURSES, _vm.featurePermissions))
                )?_c('NavItem',{attrs:{"label":"Prep Courses","isActive":[_vm.ROUTES.CLASSES_LP, _vm.ROUTES.CLASSES, _vm.ROUTES.ON_DEMAND].includes(_vm.$route.path),"isDropdown":true}},[_c('PrepCourseSelector',{on:{"fireEvent":function($event){return _vm.fireGAEvent($event)}}})],1):_vm._e(),_vm._v(" "),(
                    !_vm.isAuthenticated ||
                    _vm.isFeatureAllowed(_vm.PLATFORM_FEATURES.FREE_RESOURCES, _vm.featurePermissions)
                )?_c('NavItem',{class:_vm.isPremiumUser ? 'block' : 'hidden xl:block',attrs:{"label":"Free Resources","isActive":(_vm.$route.path.includes(_vm.ROUTES.GRE_BLOG_LISTING) ||
                        _vm.$route.path.includes(_vm.ROUTES.GMAT_BLOG_LISTING) ||
                        _vm.$route.path.includes(_vm.ROUTES.GRE_BLOGS_AND_GUIDES) ||
                        _vm.$route.path.includes(_vm.ROUTES.GMAT_BLOGS_AND_GUIDES)) &&
                    !_vm.$route.path.includes(_vm.ROUTES.CLASSES_LP) &&
                    !_vm.$route.path.includes(_vm.ROUTES.GRE_QUICK_DIAGNOSTIC),"isDropdown":true}},[_c('ResourceSelector',{attrs:{"windowWidth":_vm.windowWidth},on:{"fireEvent":function ($payload) { return _vm.fireGAEvent($payload.eventFor, $payload.resource); }}})],1):_vm._e(),_vm._v(" "),(
                    !_vm.isAuthenticated ||
                    (!_vm.isPremiumUser &&
                        !_vm.toggleLiveDemo &&
                        _vm.isFeatureAllowed(_vm.PLATFORM_FEATURES.ENQUIRE_NOW, _vm.featurePermissions))
                )?_c('NavItem',{staticClass:"hidden xl:block",attrs:{"label":"Enquire Now","externalLink":_vm.ROUTES.BOOK_DEMO},on:{"select":function($event){return _vm.fireGAEvent('bookDemo')}}}):(!_vm.isPremiumUser && _vm.toggleLiveDemo)?_c('NavItem',{staticClass:"hidden xl:block",attrs:{"label":"Live Demo","isGradientText":true,"externalLink":_vm.ROUTES.LIVE_DEMO},on:{"select":function($event){return _vm.fireGAEvent('liveDemo')}}}):_vm._e(),_vm._v(" "),(
                    !_vm.isAuthenticated ||
                    (!_vm.isPremiumUser &&
                        _vm.isFeatureAllowed(_vm.PLATFORM_FEATURES.PRICING, _vm.featurePermissions))
                )?_c('NavItem',{attrs:{"label":"Pricing","redirectTo":_vm.ROUTES.PRICING,"isGradientText":true},on:{"select":function($event){return _vm.fireGAEvent('prepPlus')}}}):_vm._e(),_vm._v(" "),(
                    !_vm.isAuthenticated ||
                    _vm.isFeatureAllowed(_vm.PLATFORM_FEATURES.FREE_RESOURCES, _vm.featurePermissions)
                )?_c('NavItem',{class:_vm.isPremiumUser ? 'hidden' : 'block xl:hidden',attrs:{"label":"More","isActive":(_vm.$route.path.includes(_vm.ROUTES.GRE_BLOG_LISTING) ||
                        _vm.$route.path.includes(_vm.ROUTES.GMAT_BLOG_LISTING) ||
                        _vm.$route.path.includes(_vm.ROUTES.GRE_BLOGS_AND_GUIDES) ||
                        _vm.$route.path.includes(_vm.ROUTES.GMAT_BLOGS_AND_GUIDES)) &&
                    !_vm.$route.path.includes(_vm.ROUTES.CLASSES_LP) &&
                    !_vm.$route.path.includes(_vm.ROUTES.GRE_QUICK_DIAGNOSTIC),"isDropdown":true,"reposition":"-right-24 "}},[_c('ResourceSelector',{attrs:{"windowWidth":_vm.windowWidth},on:{"fireEvent":function ($payload) { return _vm.fireGAEvent($payload.eventFor, $payload.resource); }}})],1):_vm._e(),_vm._v(" "),(_vm.showGreLiveTestTouchpoint)?_c('button',{staticClass:"text-sm text-theme-gray-dark h-full inline-flex items-center relative space-x-1",on:{"click":function($event){return _vm.launchLiveTest()}}},[(_vm.isLiveTestActive)?_c('img',{staticClass:"h-3.5 w-3.5 pulse-animation",attrs:{"src":_vm.recordingSymbol,"alt":"close-btn"}}):_vm._e(),_vm._v(" "),_c('span',[_vm._v(" GRE Live Test")])]):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"flex items-center gap-3"},[(!_vm.isAuthenticated)?_c('QdTestHook',{on:{"onClick":function($event){return _vm.takeGreDiagnosticTest(_vm.ANALYTIC_SOURCE_MODULES.NAVBAR)}}}):_vm._e(),_vm._v(" "),_c('client-only',[(!_vm.isAuthenticated && !Boolean(_vm.$route.query.ttid))?_c('NuxtLink',{staticClass:"btn btn-primary font-bold rounded-lg text-sm",attrs:{"to":_vm.ROUTES.LOGIN,"prefetch":_vm.$device.isMobile ? false : true},nativeOn:{"click":function($event){return _vm.handleLogin.apply(null, arguments)}}},[_vm._v("\n                Login / Sign Up\n            ")]):(_vm.isAuthenticated)?_c('div',{staticClass:"flex items-center"},[(
                        _vm.isFeatureAllowed(_vm.PLATFORM_FEATURES.FREE_TRIAL, _vm.featurePermissions) &&
                        !_vm.$device.isMobile &&
                        _vm.freeTrialStatus.activated
                    )?_c('NudgeTag',{staticClass:"mr-5",attrs:{"label":_vm.freeTrialLabel,"theme":_vm.freeTrialStatus.expired ? 'red' : 'yellow',"gradient":true,"chevron":true,"redirectTo":_vm.ROUTES.PRICING}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex cursor-pointer md:gap-2",on:{"click":_vm.handleProfileImageClick}},[_c('ProfilePic',{attrs:{"isPremiumUser":_vm.isPremiumUser,"widthInPixels":32}}),_vm._v(" "),_c('img',{staticClass:"hidden md:block",attrs:{"src":_vm.chevronGrayBold,"alt":"dropdown_arrow"}})],1)],1):_vm._e()],1),_vm._v(" "),(_vm.showDropdown)?_c('button',{staticClass:"fixed inset-0 h-full w-full cursor-default overflow-hidden",attrs:{"tabindex":"-1"},on:{"click":function($event){_vm.showDropdown = false}}}):_vm._e(),_vm._v(" "),_c('Transition',{attrs:{"name":"fade"}},[(_vm.showDropdown)?_c('div',{staticClass:"fixed top-20 right-3 md:right-10"},[_c('Dropdown',{staticClass:"min-w-[148px] max-w-[204px] md:max-w-none md:w-[204px]",attrs:{"options":_vm.dropDownData,"showDividers":true,"showIcons":true},on:{"selectOption":_vm.selectOption}})],1):_vm._e()])],1),_vm._v(" "),(_vm.isLoginModalOpen)?_c('ModalContainer',{on:{"closeModal":function($event){_vm.isLoginModalOpen = !_vm.isLoginModalOpen}}},[_c('AuthModal',{attrs:{"closable":true,"webStepZeroSignupHeading":"Start your Test Prep Journey Today!","webStepZeroSignupSubHeading":"Sign up to start a smart and smooth test-taking experience.","signup-type":"gre_live_test"},on:{"closeModal":function($event){_vm.isLoginModalOpen = !_vm.isLoginModalOpen},"userAuthenticated":_vm.launchLiveTest}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }